import { Component, EventEmitter, Input,  OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as _ from 'lodash';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, OnChanges {

  //options to be displayed on the slider
  numberOfDatesToShow = 5;
  @Input() sliderItems: string[];
  @Input() lastSelectedDateIndex: number;
  // event emitter to emit the selected item
  @Output() onSelect = new EventEmitter();
  // visible items on the slider
  visibleItems: string[] = [];
  // index of the last visible item
  lastVisibleItemIndex: number = 0;
  // index of the selected item
  selectedItemIndex: number;
  //Use property to handle active css
  showAllDates: boolean = true;

  constructor(
    private platformService: Platform,
  ){}

  ngOnInit() {
    if (this.platformService.is('mobile')) {
      this.numberOfDatesToShow = 1;
    }
    this.updateVisibleItems();
  }

  ngOnChanges(change: SimpleChanges) {
    // Check if `sliderItems` has changed and if it has a valid current value
    if(change && change.sliderItems && change.sliderItems.currentValue) {
      // Update `sliderItems` with the new value and update the visible items
      this.sliderItems = change.sliderItems.currentValue
      this.updateVisibleItems();
    }

    // If `lastSelectedDateIndex` has changed and the new value is `null`, show all dates
    if(change.lastSelectedDateIndex && change.lastSelectedDateIndex.currentValue === null) {
      this.showAllDates = true;
    }

    // If `lastSelectedDateIndex` has changed and the new value is a valid number, update the visible items
    if(change.lastSelectedDateIndex && (typeof (change.lastSelectedDateIndex.currentValue) === 'number')) {
      this.updateVisibleItems();
    } 
  }

  updateVisibleItems() {
    //If no current index found, initialize it to 0
    if(!this.lastVisibleItemIndex || this.showAllDates) {
     this.lastVisibleItemIndex = 0;
    }
    
    //   //Load 5 dates on the slider as next or previous is clicked and make the first shift of each group as active
    let activeIndex = this.lastVisibleItemIndex;
    // if the remaining items are less than 5, show the remaining items or show the next 5 items 
    const endIndex = Math.min(this.lastVisibleItemIndex + (this.numberOfDatesToShow -1), this.sliderItems.length - 1);
   
    this.visibleItems = this.sliderItems.slice(activeIndex, endIndex + 1);
    
    //Active index will be the last selected index, the last visible item 
    activeIndex = this.lastSelectedDateIndex ? this.lastSelectedDateIndex : activeIndex;
  
    //Show first 5 jobs in slider
    if(!this.visibleItems.length) {
      let starting = this.sliderItems.length % this.numberOfDatesToShow === 0 ? this.sliderItems.length - this.numberOfDatesToShow : this.sliderItems.length - this.sliderItems.length % this.numberOfDatesToShow;
      this.visibleItems = this.sliderItems.slice(starting, this.sliderItems.length);
    }

    
    //Add flag to assign CSS to active tab
    //Update view each time next is clicked, emit function 
    if(!this.showAllDates) {
      this.activateSliderOption(this.sliderItems[activeIndex], 0);
    }
    
  }

  activateSliderOption(selectedItem: string, index: number) {
    console.log('item clicked: ', index);
    this.showAllDates = false;

    this.selectedItemIndex = this.lastSelectedDateIndex ? this.lastSelectedDateIndex : index;   
    //Emit to update active shift by passsing the selected date from slider 
    this.onSelect.emit({
      selectedItem
    });
  }
  
  previousItem() {
    //Update view each time next is clicked, disable all dates CSS
    this.showAllDates = false;
    //Load previous 5 dates on the slider
    // if last visible item index is greater than 0, show the previous 5 items, else do nothing
    if (this.lastVisibleItemIndex) {
      this.lastVisibleItemIndex -= this.numberOfDatesToShow;
      this.lastSelectedDateIndex = null; //As slider is moved from its last selected date, remove the last selected value, so it can take the new active index 
      this.updateVisibleItems();

    }
  }

  nextItem() {
    //Update view each time next is clicked, disable all dates CSS 
    this.showAllDates = false;
    //Load next 5 dates on the slider
    if (this.lastVisibleItemIndex <= this.sliderItems.length - 1) {
      this.lastVisibleItemIndex += this.numberOfDatesToShow;
      this.lastSelectedDateIndex = null; //As slider is moved from its last selected date, remove the last selected value, so it can take the new active index
      this.updateVisibleItems();

    }
  }

  handleShowAllDates() {
    //Update flag when all dates is clicked and update the view by emitting empty event indicating that all dates is selected
    this.showAllDates = true;
    this.updateVisibleItems();
    this.onSelect.emit({});
  }
 }