import { ISetting } from '../interfaces/settings.interface';
import { UtilService } from '../../shared/services/util.services';
import { PopupService } from '../../shared/services/popup.service';
import { JobService } from '../../shared/services/job.service';
import { BadgeService } from '../../shared/services/badges.service';
import { EditPartialUserInfoComponent } from 'src/modules/shared/components/edit-partial-user-info/edit-partial-user-info.component'
import { getUserInfoAction, updateUserPartialState } from 'src/modules/authentication/+store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { AlertController, ModalController } from '@ionic/angular';
import { Injectable, Output } from '@angular/core';
import * as moment from 'moment';
import { OnBoardingStafferService } from 'src/modules/onboarding-staffer/services/onboarding-staffer.service';
import { forEach } from 'lodash';
import { ITalentJob } from '../interfaces/talent-job.interface';
import { CertificateValidationRulesService } from 'src/modules/shared/services/certificate-validation-rules.service';
import { AttestationAndDeclarationService } from 'src/modules/shared/services/attestation-and-declaration.service';
import { Router } from '@angular/router';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { CertificateService } from 'src/modules/shared/services/certificate.service';
import { UserService } from 'src/modules/shared/services/user.service';
import { DownloadService } from 'src/modules/shared/services/download.service';

@Injectable({
  providedIn: 'root'
})
export class TalentJobChecksService {
  settings: ISetting;
  userData: any;
  // TODO think of a better way to do this
  updateUser: any;

  constructor(
    // private talentDashboardUtilService: TalentDashboardUtilService,
    // private imagesService: ImagesService,
    private utilService: UtilService,
    private modalController: ModalController,
    private popupService: PopupService,
    private jobService: JobService,
    private alertController: AlertController,
    private badgeService: BadgeService,
    private authStore: Store<IAuthState>,
    private onBoardingStafferService: OnBoardingStafferService,
    private certificateValidationRulesService: CertificateValidationRulesService,
    private attestationAndDeclarationService: AttestationAndDeclarationService,
    private loadingService: LoadingService,
    private router: Router,
    private certificateService: CertificateService,
    private userService: UserService,
    private downloadService: DownloadService,
    private store: Store<IAuthState>
  ) {}

  async performChecks(jobItem, settings: ISetting[], user, updateUser): Promise<{ canApply: boolean; rcmpAccepted: boolean }> {
    this.userData = { ...user };
    this.settings = { ...settings[0] };
    this.updateUser = updateUser;

    const result = { canApply: true, rcmpAccepted: false, profileApproved: false };
    
    try {

      if(!user.profile_approved && !user.isSignupProcessComplete) {
        return result;
      }

    
      if(!user.profile_approved && user.isSignupProcessComplete) {
        result.canApply = false;
        await this.underReviewPopup()
      }

      // Check if user has already applied on the job or not
      this.EnsureNotAlreadyApplied(jobItem);

      await this.ensureRecurringWarning(jobItem);

      // check if the vss attestation is enabled
      if (this.settings.enableVssAttestation) {
        // it is
        await this.checkVSS();
      }

      // Show Job Disclaimer
      await this.ensureDisclaimerAcceptance(jobItem);

      // If the job demands 14 days of isolation, ensure that user meet that
      // criteria
      // await this.Ensure14DaysCheck(jobItem);

      // Ensure that user signs waiver of liability form
      await this.EnsureWaiverOfLiability(jobItem);

      // Ask to perform RCMP / background check if required
      result.rcmpAccepted = await this.askForRcmpPermission(jobItem);

      // check if badge required for the shift
      await this.requestForBadgeIfRequired(this.userData, jobItem);

      //Check if VSC is uploaded
      await this.ensureContractorHasValidDocuments(jobItem, this.userData);

      this.authStore.dispatch(getUserInfoAction());
      result.profileApproved = this.userData.profile_approved;

      // free up memory and clear the variables
      this.userData = null;
      this.settings = null;
      this.updateUser = null;

      return result;
    } catch (err) {
      // any check failed
      console.log('error: ', err);
      return { ...result, canApply: false };
    }
  }

  // Check ID badge criteria and apply for badge
  private async requestForBadgeIfRequired(user, jobItem){
    
    // Check ID badge criteria
    const hasClearedRequiredChecks = await this.performChecksForIdBadge(user, jobItem);

    if(!hasClearedRequiredChecks){
      // contractor has the badge or its not required
      return;
    }

    // Show promot for ID badge
    const userResponse = await this.showPromptForIDBadge(user);
    
    // download badge
    if(userResponse == false){
      await this.downloadPhysicalBadge(jobItem);
    }
    
    throw new Error('Badge required!');
    // User now have a badge printed, updating user.
    // await this.badgeService.updatePhysicalBadge(user._id, true);
    // this.store.dispatch(updateUserPartialState({  partialUserState: { hasPhysicalBadge: true } }))
  }

  async downloadPhysicalBadge(job) {
    const loading = await this.loadingService.showLoading().toPromise();
    try {
      const badge = await this.userService.getPhysicalBadge(this.userData._id, job.skill);
      const pdfData = badge.attachment;
      this.downloadService.downloadPdf(pdfData, 'physicalBadge');
    } catch (error) {
      console.log('err', error);
      this.popupService.showModal({
        heading: 'Error',
        message: typeof error.error === 'string' ? error.error : 'Sorry, Unable to download, try again',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png',
      });
    } finally {
      await this.loadingService.hideLoading(loading);
    }
  }

  
  // Show prompt for ID badge
  private async showPromptForIDBadge(userData){
    return new Promise(async (resolve) => {
          const message = `This shift requires a physical badge. Please download the file and have it printed at your local TPH store or a similar service provider. Request laminated cardboard or plastic. Send us a photo via SMS or email once printed.  Please note that any printing costs will be your responsibility, and while we appreciate your efforts, we’re unable to guarantee shift availability. Thank you for your understanding.`;
          this.popupService.showModal(
            {
              heading: 'Shift requires badge:',
              message: `${message}`,
              btn: 'Download',
              rejectBtnText: 'Cancel',
              navigateRoute: null,
              imgURL: 'assets/images/sorry1.png',
            },
            async () => {
              // download the badge
              resolve(false);
            }
      );
    })
  }

  private async performChecksForIdBadge(user, jobItem){

      // if user already has physical badge
      if(user.hasPhysicalBadge || 
      // entity doesn't require physical badge
        !jobItem.entity.requiresPhysicalBadge || 
      // user doesn't have healthcare skill
        !this.utilService.doesSkillRequirePhysicalBadge(jobItem.skill)){
        return false;
      }
      
      // Get badges
      const badgeResp = await this.badgeService.getBadges(user._id);

          // Check length of badges
      if(badgeResp && badgeResp.badges && badgeResp.badges.length) return false;

      return true;
  }

  private async ensureRecurringWarning(jobItem): Promise<void> {

      // show warning for Recurring
      let shouldContinueAfterWarning;
      if (jobItem.recurringShift.isTrue && this.settings.contractorNeedsToWorkAllShiftsInRecurringWarning) {
        shouldContinueAfterWarning = await this.popupService.recurringWarning();
      }

      if (jobItem.recurringShift.isTrue && shouldContinueAfterWarning === false) {
        throw new Error('PRESSED_CANCEL_ON_RECURRING_WARNING');;
      } 
  }
  private async ensureDisclaimerAcceptance(jobItem): Promise<void> {
    return new Promise((resolve, rejectPromise) => {
      if (!jobItem.disclaimer || !jobItem.disclaimer.length) {
        return resolve();
      }

      const data = {
        action: '',
        jobId: jobItem._id,
        entityId: jobItem.entity._id,
        email: this.userData.email,
        firstName: this.userData.firstName,
        lastName: this.userData.lastName
      };

      this.popupService.showModal(
        {
          heading: 'Notice',
          message: jobItem.disclaimer,
          btn: 'Confirm',
          rejectBtnText: 'Reject',
          navigateRoute: null,
          imgURL: null
        },
        () => {
          data.action = 'accept';
          this.jobService.logDisclaimer(data);
          resolve();
        },
        () => {
          data.action = 'reject';
          this.jobService.logDisclaimer(data);
          rejectPromise();
        }
      );
    });
  }

  private underReviewPopup(): void {
    this.popupService.showModal({
      heading: 'Your account is pending approval',
      message: 'Our team is reviewing your profile, once you are approved you will be able to apply for shifts.',
      btn: 'Got it',
      navigateRoute: 'talent-dashboard/home',
      imgURL: 'assets/images/profile.png'
    });
  }

  private EnsureNotAlreadyApplied(jobItem): void {
    if (jobItem.isApplied) {
      // this.availableLoadedJobs[jobIndex].isApplied = true;
      this.popupService.showPopup('Oops', 'Sorry, You have already applied on the shift.');
      throw new Error('ALREADY_APPLIED');
    }
    return;
  }

  private Ensure14DaysCheck(jobItem): void {
    // 14 days check
    if (
      jobItem.entity.creator.companyType === 'Healthcare' &&
      this.userData.lastJobCompleted &&
      !this.userData.lastJobCompleted.byPass &&
      this.userData.lastJobCompleted.companyId !== jobItem.entity._id &&
      !jobItem.entity.creator.disable14DayCheckOnClient &&
      !(this.userData.badges.indexOf('covid-19-vaccine') > -1) &&
      this.utilService.not14daysPast(jobItem.shiftStartTime, this.userData.lastJobCompleted.workedOn)
    ) {
      this.popupService.showModal({
        heading: 'Sorry',
        message:
          'Due to the single site order, you cannot apply for this job. You must isolate for 14 days after your last shift and provide a negative covid test at the end of your isolation period less than 7 days old. If you are fully vaccinated, please upload your supporting documentation as this isolation period may be waived.',
        btn: 'Ok',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
      throw new Error('14_DAYS_CHECK_FAILED');
    }
  }

  private EnsureWaiverOfLiability(jobItem): void {
    // Waiver of Liability / Hello Sign
    if (!this.userData.hasAccessToApplyOnShifts) {
      this.popupService.showModal({
        heading: 'Please sign the Waiver of Liability',
        message: 'To be able to apply to this shift, you will need to sign the Waiver of liability.',
        btn: 'Ok',
        navigateRoute: 'talent-dashboard/hello-sign',
        imgURL: 'assets/images/profile.png'
      }, () => {
        this.hideNotificationPopup();
      });

      throw new Error('WAIVER_LIABILITY_NOT_SIGNED');
    }
  }

  private async askForRcmpPermission(jobItem): Promise<boolean> {
    if (
      jobItem.vunerableCheckRequired &&
      !this.userData.rcmpVerification.verified &&
      !this.userData.rcmpVerification.invitationSent &&
      !this.userData.byPassRCMPVerification
    ) {
      return this.askForRCMP();
    }
    return false;
  }

  private async checkVSS(): Promise<void> {
    return new Promise((resolve, rejectPromise) => {
      if (
        this.userData.vss &&
        // if user has already submitted vss
        this.userData.vss.submitted_on &&
        // and its not 12 months old
        this.utilService.not12monthsPassed(this.userData.vss.submitted_on) &&
        // and its accespted
        this.userData.vss.accepted
      ) {
        // we are fine, user can apply
        resolve();
      } else {
        // ask to fill vss
        this.popupService.showModal(
          {
            heading: 'VSS ATTESTATION',
            message: `Do you attest to the following?`,
            list: [
              `Do you attest that you have not been charged with, or convicted of, a criminal offence that would preclude you from working with, or around a “vulnerable person",   as defined in section 6.3 of the Criminal Records Act, R.S.C. 1985, c. C-47 as “… a person who, because of his or her age,    a disability or other circumstances, whether temporary or permanent`,
              `(a) is in a position of dependency on others; or `,
              `(b) is otherwise at a greater risk than the general population of being harmed by a person in a position of trust or authority towards them?`
            ],
            btn: 'Yes, you attest',
            rejectBtnText: 'No, you don\'t attest',
            navigateRoute: null,
            imgURL: null
          },
          async accept => {
            // Attestation Accepted
            await this.onBoardingStafferService.updateUser(this.userData._id, {vss: {
              accepted: true,
              submitted_on: moment().unix()
            }}) 
            this.popupService.showModal(
              {
                heading: 'Attestation accepted.',
                message: 'You can continue applying jobs now',
                btn: 'Ok',
                navigateRoute: null,
                imgURL: 'assets/images/thumbs-up.png'
              },
              () => {
                resolve();
              }
            );
          },
          async rejected => {
            // Attestation Rejected
            this.popupService.showModal(
              {
                heading: 'VSS Attestation',
                message: `You must attest that you're free of criminal convictions to apply to this position`,
                btn: 'Ok',
                navigateRoute: null,
                imgURL: 'assets/images/profile.png'
              },
              () => {
                rejectPromise();
              }
            );
          }
        );
      }
    });
  }

  private async askForRCMP(): Promise<boolean> {
    return new Promise(async (resolve, rejectPromise) => {
      const alert = await this.alertController.create({
        header: 'RCMP check required',
        cssClass: 'alert-modal',
        message: `Please note this client required an RCMP check in order to qualify.
         There will be a $50 charge that will charged to your next invoice. 
         You'll also be emailed a copy, and a badge will appear on your profile. This is a one-time charge.`,
        buttons: [
          {
            text: 'Reject',
            cssClass: 'danger',
            handler: () => {
              rejectPromise();
            }
          },
          {
            text: 'Accept',
            handler: () => {
              resolve(true);
            }
          }
        ]
      });

      alert.present();
    });
  }

  public async ensureContractorHasValidDocuments(jobItem: ITalentJob, userData: IUser): Promise<void> {
    return new Promise(async (resolve, rejectPromise) => {

      const certificatesSpecification = await this.certificateService.getAllCertificates();
   
      const timeZone = this.utilService.getTimezone(userData.address.province);
      const loading = await this.loadingService.showLoading().toPromise();
      const userVSCProperties = await this.attestationAndDeclarationService.fetchContractorVSCProperties(userData._id);

      if(!this.utilService.isVSCRequired(jobItem.skill) && (!userVSCProperties || !userVSCProperties.length)) {
        await this.loadingService.hideLoading(loading);
        resolve();
        return;
      }

      if(!this.utilService.isVSCRequired(jobItem.skill) ||  userVSCProperties[0].byPassVSCFlow) {
        await this.loadingService.hideLoading(loading);
        resolve();
        return;
      }

      if(!certificatesSpecification || !certificatesSpecification['Vulnerable Sector Check']) {
        return;
      }
      const VSCSpecification = certificatesSpecification['Vulnerable Sector Check'];
      
      const declarationInterval = VSCSpecification.declarationSigningDuration[0].showDeclPopupTill;
      
      const {period, duration} = this.certificateValidationRulesService.parseValidityString(declarationInterval);

      const isDeclarationValid = this.certificateValidationRulesService.checkIfDeclarationIsValid(userVSCProperties[0].vscDeclarationExpiry, timeZone);

      const hideDeclarationSignPopup = this.certificateValidationRulesService.unitOfTimePassedSinceDeclarationExpired(userVSCProperties[0].vscDeclarationExpiry, timeZone, period) >= parseInt(duration);
      
      //Allow shift operations 
      if(
        //if skill does not require VSC
        //Check if contractor has a valid VSC
                
        await this.checkForValidVSCCertificate(jobItem, userData._id, timeZone, isDeclarationValid) ||

        //Check if contractor has a valid Receipt of VSC
        await this.checkForValidReceiptOfVSCCertificate(jobItem, userData)) {
          //Allow contractor to apply for the job but first sign the declaration
          if(!userVSCProperties[0].vscDeclarationExpiry || (!isDeclarationValid && !hideDeclarationSignPopup)) {
            await this.loadingService.hideLoading(loading);
            rejectPromise();
            await this.showDeclarationPopup();
          }

          //Hide declaration popup is the declaration popup has passed 6 weeks
          if(!isDeclarationValid && hideDeclarationSignPopup) {
            await this.loadingService.hideLoading(loading);
            this.reuploadCertificate();
            rejectPromise();  
            return;
          }

          if(isDeclarationValid) {
            await this.loadingService.hideLoading(loading);
            resolve();
            return;
          }

          await this.loadingService.hideLoading(loading);
          resolve();
        }
       //Block shift operations
        else {
          let error = '';
         //If none of the above is true, it means that the declaration was required but no valid declaration found
          //IF: VSC Certificate exists within 3 years of issue date, 
          //THEN: If found, prompt to sign declaration
          //ELSE: NEW VSC Required

          //Skill requires VSC and contractor has a declaration that is valid //if the expiry date of declaration is valid and haven't crossed the expiry date
        
          const doesContractorHasVSCWithinXYears = await this.attestationAndDeclarationService.doesContractorHasVSCWithinXYears(userData._id, 'Vulnerable Sector Check', '3', 'years');
         
          //If contractor doesn't have any VSC certificate that was uploaded 3 years ago. Show a popup to upload new VSC or request letter
          //Redirect to certificate screen
          if(!doesContractorHasVSCWithinXYears || !doesContractorHasVSCWithinXYears.length) {
            const doesContractorHasValidReceipt = await this.attestationAndDeclarationService.doesContractorHasVSCWithinXYears(userData._id, 'Receipt of Vulnerable Sector Check', '8', 'weeks');
            //If the don't have a valid VSC receipt (issued within last 8 weeks), then ask for a new receipt/VSC
            if(!doesContractorHasValidReceipt || !doesContractorHasValidReceipt.length) {
              await this.loadingService.hideLoading(loading);
              this.reuploadCertificate();
              rejectPromise();  
              return;
            }

            //If the contractor doesn't have a VSC within 3 years or if the contractor doesn't have a VSC at all,
            //Check if they've a valid VSC receipt (issued within last 8 weeks), then allow the contractor to work
            await this.loadingService.hideLoading(loading);
            this.reuploadCertificate();
            rejectPromise();  
            return;  
          } else {
            //Give contractor additional 6 weeks to sign popup, if 6 weeks are passed dont show declaration sign popup anymore
            //Block contractor from applying for the shifts unless new VSC or receipt is signed
            if(!isDeclarationValid && hideDeclarationSignPopup) {
              await this.loadingService.hideLoading(loading);
              this.reuploadCertificate();
              rejectPromise();  
              return;
            } 

            //Check if there is any certificate that's within 3 years and declaration popup is still showing
            if(isDeclarationValid && !hideDeclarationSignPopup) {
              await this.loadingService.hideLoading(loading);
              resolve();
              return;
            } 

            await this.loadingService.hideLoading(loading);
            rejectPromise();
            await this.showDeclarationPopup();
            throw error = 'Declaration Expired';
          }
      }
    });
  }


  async checkForValidVSCCertificate(jobItem: ITalentJob, userId: string, timeZone: string, isDeclarationValid: boolean) {
    try {
      const VSCCertificate = await this.attestationAndDeclarationService.doesContractorHasVSCWithinXYears(userId, 'Vulnerable Sector Check', '6', 'months');
      return this.utilService.isVSCRequired(jobItem.skill) && 
      isDeclarationValid && VSCCertificate.length > 0 
    } catch(error) {
      throw 'Failed to fetch Vulnerable Sector Check Credential. Please try again';
    }
  }

  async checkForValidReceiptOfVSCCertificate(jobItem: ITalentJob, userData: IUser ) {
    try {
      const receiptOfVSCCertificate = await this.attestationAndDeclarationService.doesContractorHasVSCWithinXYears(userData._id, 'Receipt of Vulnerable Sector Check', '8', 'weeks');
      return (this.utilService.isVSCRequired(jobItem.skill) && 
      receiptOfVSCCertificate.length > 0)
    } catch(error) {
      throw 'Failed to fetch Receipt of Vulnerable Sector Check Credential. Please try again';
     }
  }

  hideNotificationPopup() {
    const elements = document.getElementsByClassName('notificationListModal');
    
    if(elements && elements.length) {
      forEach(elements, (elem) => {
        if(elem['style']){
          elem['style'].display = 'none';
        }
      })
    }
  }

  reuploadCertificate() {
    this.popupService.showModal(
      {
        heading: 'Vulnerable Sector Check Required',
        message: `Before requesting this shift, please upload valid Vulnerable Sector Check credential`,
        btn: 'Upload',
        rejectBtnText: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/notice.png'
      },
      async accept => {
       //Redirect to profile to upload certificate / receipt if no valid VSC or receipt found
       this.hideNotificationPopup();
       this.router.navigateByUrl('talent-dashboard/profile');
        return;
      },

      async rejected => {
        return;
      }
    );
  }

  async showDeclarationPopup() {
    // ask to sign declaration
    await this.popupService.showModal(
      {
        heading: 'Please complete the Vulnerable Sector Check Declaration',
        message: 'To continue applying to shifts, please complete the Vulnerable Sector Check Declaration',
        btn: 'Ok',
        rejectBtnText: '',
        navigateRoute: 'talent-dashboard/sign-declaration',
        imgURL: 'assets/images/notice.png'
      },  () => {
        // Declaration sign clicked, redirect to declaration page
        this.hideNotificationPopup();
        
      }
    );
  }
}
