<div class = "card-wrapper">
  <h1 class = "flex-grow">Edit Logged Hours</h1>
  <div class = "range-slider flex-grow">
    <div class="timing-section">
      {{timeVal?.lower | momentPipeUnix:'h:mm a'}} -  {{timeVal?.upper | momentPipeUnix:'h:mm a'}}
    </div>

    <div class="ranger-sec">
      <ion-range id="dual-range" class="ranger" dual-knobs [(ngModel)]="timeVal" 
        [min]="slider.min" [max]="slider.max" step="900">
      </ion-range>
    </div>
  </div>
  
  <div class = "button-stack flex-grow">
    <button (click)="cancel()" class="btn cancel">
      Cancel
    </button>
    <button (click)="submit()" class="btn submit">
      Submit
    </button>
  </div>
</div>
