import { HttpPromiseService } from './http-promise.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class BadgeService {

    constructor(
        private httpPromiseService: HttpPromiseService,

    ) { }

    public applyForBadge(userId: string ) {
        return this.httpPromiseService.httpPostRequest(
            `/api/badgeRequests`,
            {userId: `${userId}`}
        );
    }

    public updatePhysicalBadge(userId: string, status: boolean ) {
        return this.httpPromiseService.httpPutRequest(
            `/api/users/${userId}/updatePhysicalBadge`, { status }
        );
    }

    public getBadges(userId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/badgeRequests?id=${userId}`
        );
    }
}
