import { Injectable } from '@angular/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Platform } from '@ionic/angular';
import { PopupService } from './popup.service';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { File } from '@ionic-native/file/ngx';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(
    private platform: Platform,
    private transfer: FileTransfer,
    private fileopen: FileOpener,
    private file: File,
    private popupService: PopupService,
    private talentJobFormatService: TalentJobFormatService
  ) {}

  async downloadPdf(pdfData: string, fileName: string) {
    const linkSource = `data:application/pdf;base64,${pdfData}`;
    const downloadLink = document.createElement('a');
    const completefileName = `${fileName}.pdf`;

    if ((this.platform.is('android') || this.platform.is('ios') || this.platform.is('ipad')) && !this.talentJobFormatService.isBrowserCheck()) {
      const fileTransfer: FileTransferObject = this.transfer.create();

      const path = this.file.dataDirectory;

      fileTransfer
        .download(linkSource, path + completefileName)
        .then(entry => {
          this.popupService.showModal(
            {
              heading: 'Success',
              message: 'Download complete!',
              btn: 'Open',
              navigateRoute: null,
              imgURL: 'assets/images/thumbs-up.png'
            },
            () => {
              this.fileopen.open(path + completefileName, 'application/pdf');
            }
          );
        })
        .catch(err => {
          console.log(err);
          alert('Error saving file: ' + err.message);
        });
    } else {
      // If downloaded by Web Browser or ios
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
}
