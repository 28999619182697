import { HttpPromiseService } from './http-promise.service';
import { HttpService } from './http.service';
import { IUserAvailability } from 'src/modules/authentication/interfaces/user-availability.interface';
import { ICompanyInformation } from 'src/modules/onboarding-company/interfaces/company-information.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { IAddressConfirmation } from '../enums/company-address-confirmation.interface';
import { IUserSignupPendingState } from 'src/modules/onboarding-company/interfaces/company-signup-pending-state.interface.';
import { IUpdateUser } from 'src/modules/authentication/interfaces/update-user.interface';
import { Injectable } from '@angular/core';
import { ISetAvailability } from 'src/modules/talent-dashboard/interfaces/set-availability.interface';
import { IUserAddress } from 'src/modules/authentication/interfaces/user-address.interface';
import { ITask } from 'src/modules/authentication/interfaces/task.interface';
import { ICertificateSpecification } from 'src/modules/talent-dashboard/interfaces/certificate-specification.interface';
@Injectable({ providedIn: 'root' })

export class UserService {

    constructor(
        private httpPromiseService: HttpPromiseService,
        private http: HttpService,

    ) { }

    public me() {
        return this.http.httpGetRequest(
            '/api/users/me');
    }

    public markBlocked() {
        return this.http.httpPostRequest(
            '/api/users/markBlocked',
            {});
    }

    public getUser() {
        return this.httpPromiseService.httpGetRequest(
            `/api/users/me`
        );
    }
    public getCardList(userId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/users/cardList/${userId}`
        );
    }


    public updateUserAvailability(
        stafferId: string,
        fixedAvailability: IUserAvailability[],
        fixedStatus: boolean,
        scheduleAvailability?: unknown,
        scheduleStatus?: unknown) {
        const data = {};
        if (fixedAvailability) {
            data['availability_schedule'] = fixedAvailability,
                data['availability_status'] = fixedStatus;
        }
        if (scheduleAvailability) {
            data['schedule'] = {
                availability: scheduleAvailability,
                status: scheduleStatus
            }
        }
        return this.httpPromiseService.httpPutRequest(
            `/api/users/${stafferId}`, data
        );
    }

    public updateUserAggregatedRatings(
        userId: string,
    ) {
        return this.httpPromiseService.httpPutRequest(
            `/api/users/${userId}/aggregated_rating`, {}
        );
    }



    public createUser(data: ICompanyInformation) {
        return this.http.httpPostRequest<{ token: string }>(
            '/api/users/createUser',
            data
        );
    }

    public addressConfirmation(userId: string, data: IAddressConfirmation) {
        return this.http.httpPutRequest<IUser>(
            `/api/users/${userId}`,
            data
        );
    }

    public updateUserSignupPendingState(userId: string, data: IUserSignupPendingState) {
        return this.http.httpPostRequest(
            `/api/users/updateUserSignupPendingState/${userId}`,
            data
        );
    }

    public sendContractToEmail(data) {
        return this.http.httpPostRequest(
            `/api/users/contractSend/all`,
            data
        );
    }
    public sendUserInvitationEmail(userId: string, data: { email: string, linkCode: string }) {
        return this.httpPromiseService.httpPatchRequest(
            `/api/users/sendEmail/${userId}`,
            data
        );
    }

    public updateUser(userId: string, data: IUpdateUser) {
        return this.http.httpPutRequest<IUser>(
            `/api/users/${userId}`,
            data
        );
    }

    public updateStafferAvailability(userId: string, data:
        {
            availability_schedule?: ISetAvailability[],
            availability_status?: boolean
        }) {
        return this.httpPromiseService.httpPutRequest(
            `/api/users/${userId}/updateStafferAvailability`,
            data
        );
    }

    public updateStafferAddress(userId: string, data: IUserAddress) {
        return this.httpPromiseService.httpPutRequest(
            `/api/users/${userId}/updateStafferAddress`,
            data
        );
    }

    public addCompanyLogo(data: FormData) {
        return this.http.httpPostRequest<IUser>(
            `/api/users/avatar`,
            data
        );
    }
    public userCreditCardAdd(userId: string, data) {
        return this.httpPromiseService.httpPostRequest(
            `/api/users/checkout/all/${userId}`,
            { token: data }
        );
    }

    public addCard(userId: string, token: string) {
        return this.httpPromiseService.httpPutRequest(
            `/api/users/addCard/${userId}`,
            { token }
        );
    }
    public setDefaultCard(userId: string, cardId: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/users/setDefaultCard/${userId}`,
            { cardId }
        );
    }

    public listForBlockingClients(stafferId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/users/list/unblocked/?findListOf=entities&stafferId=${stafferId} `
        );
    }

    public listForBlockingStaffies(entityId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/users/list/unblocked/?findListOf=staffers&entityId=${entityId} `
        );
    }

    public blockbyId(userId: string, body: { blockedId: string, type: string }) {
        return this.httpPromiseService.httpPostRequest(
            `/api/users/blockbyId/${userId} `, body
        );
    }

    public unblockbyId(userId: string, body: { blockedId: string, type: string }) {
        return this.httpPromiseService.httpPostRequest(
            `/api/users/unblockbyId/${userId} `, body
        );
    }

    public updateUserPromise(userId: string, data) {
        if(data.__v)
            delete data.__v;
        
        return this.httpPromiseService.httpPutRequest(
            `/api/users/${userId}`,
            data
        );
    }
    public updateUserName(userId: string, userName: string, ) {
        return this.httpPromiseService.httpPutRequest(
            `/api/users/username/${userName}/${userId}`,
            {}
        );
    }

    public applyForBadge(userId: string, val: boolean, requestForAddressUpdate?:boolean ) {
        return this.httpPromiseService.httpPutRequest(
            `/api/users/applyForBadge/${userId}`,
            {hasAppliedForBadge: val, requestForAddressUpdate: requestForAddressUpdate}
        );
    }
    
    public getContract(userId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/users/stafferContract/download/${userId}`
        );
    }

    public getPhysicalBadge(userId: string, skill: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/users/${userId}/physicalBadge/${skill}/download`
        );
    }

    public fetchStafferCertificates(userId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/certificates/getAllCertificatesByStafferId/${userId}?showDeleted=${true}`);
    }

    public fetchStafferCertificatesByType(userId: string, type: string) {
        if(type.includes('&'))
            type = encodeURIComponent(type);
      
        return this.httpPromiseService.httpGetRequest(
            `/api/certificates/getStafferCertificateByType/${userId}?type=${type}`);
    }

    public uploadMultipleCertificates(formData: FormData) {
        return this.httpPromiseService.httpPostRequest(
            `/api/certificates/uploadMultipleCertificates`, formData
        );
    }

    public replaceCertificate(formData: FormData) {
        return this.httpPromiseService.httpPutRequest(
            `/api/certificates/update`, formData
        );
    }

    public uploadCertificates(formData: FormData) {
        return this.httpPromiseService.httpPostRequest(
            `/api/users/uploadCertificates`, formData
        );
    }

    public removeCertificate(userId: string, certificateId: string, isCOVID: boolean) {
        return this.httpPromiseService.httpDeleteRequest(
            `/api/certificates/deleteCertificate/${userId}/${certificateId}/?isCOVID=` + isCOVID
        );
    }

    public removeCertificateForStaffer(userId: string, certificateId: string, type: string) {
        return this.httpPromiseService.httpPutRequest(
            `/api/certificates/removeRejectedCertificateForStaffer/${certificateId}`, {
                certificateId: certificateId, type: type
            }
        );
    }

    public updateAddressForCompany(employerId: string, data: {
        otherAddresses: IUserAddress[],
        address: IUserAddress,
    }) {
        return this.httpPromiseService.httpPutRequest(
            `/api/users/${employerId}`, data
        );
    }

    public addNewTaskIntoCompany(employerId: string, newTask) {
        return this.httpPromiseService.httpPostRequest(
            `/api/entitys/${employerId}/tasks`, {task: newTask}
        );
    }

    public editCompanyTask(employerId: string, task: ITask) {
        return this.httpPromiseService.httpPutRequest(
            `/api/entitys/${employerId}/tasks/${task._id}`, {task}
        );
    }

    public removeCompanyTask(employerId: string, taskId: string) {
        return this.httpPromiseService.httpDeleteRequest(
            `/api/entitys/${employerId}/tasks/?taskId=${taskId}`
        );
    }

    public addNewPatientIntoCompany(employerId: string, newPatient: {id: string, address: IUserAddress}) {
        return this.httpPromiseService.httpPostRequest(
            `/api/entitys/${employerId}/patient`, newPatient
        );
    }

    public editCompanyPatient(employerId: string, patient: {_id: string, id: string, address: IUserAddress}) {
        return this.httpPromiseService.httpPutRequest(
            `/api/entitys/${employerId}/patient/${patient._id}`, {patient}
        );
    }

    public removeCompanyPatient(employerId: string, patientId: string) {
        return this.httpPromiseService.httpDeleteRequest(
            `/api/entitys/${employerId}/patient/${patientId}`
        );
    }

    public removeTaskFromCompany(employerId: string, task: string) {
        return this.httpPromiseService.httpDeleteRequest(
            `/api/entitys/${employerId}/tasks?task=${task}`,
        );
    }

    public ensureEmailNotExists(email: string, type: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/users/ensureEmailNotExists?accountType=${type}`, { email }
        );
    }

    public ensureCompanyNameNotExists(companyName: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/users/ensureCompanyNameNotExists`, { companyName }
        );
    }

    public isUserExistInWhiteList(email: string, userId) {
        return this.httpPromiseService.httpPostRequest(
            `/api/createUserFromAdmin/checkEmails`, { email, userId }
        );
    }

    public getProfileInfo(userId: string) {
        return this.httpPromiseService.httpGetRequest(
            `/api/users/${userId}/view-staffer-profile-data`
        );
    }

    public getApprovedCertificatesOfStaffer(userId: string, isCOVID: boolean) {
        return this.httpPromiseService.httpGetRequest(
            `/api/certificates/getApprovedCertificatesOfStaffer/${userId}/?isCOVID=`+isCOVID
        );
    }

    public createTrainingRequest(entityId: string, entityName: string, jobId: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/trainingRequest/`, { entityId, entityName, jobId }
        );
    } 
    
    public extractSubSkillsFromErrorMessage(errorMessage){
        return errorMessage
          .replace('You do not have the required', '')
          .replace('to work this shift.', '')
          .replace('(', '')
          .replace(')', '')
          .replace('subskills', '')
          .replace('subskill', '')
          .replace('"', '')
          .replace('"', '')
          .trim();
    }
    
    public createSubSkillsRequest(userId: string, subSkills: string) {
        
        return this.httpPromiseService.httpPostRequest(`/api/users/subSkillsRequest/forContractor/`, { userId, subSkills });
    } 
    public getSubSkills() {
        return this.httpPromiseService.httpGetRequest(
            `/api/industries/subSkill/list`
        );
    }

    public sendCodeToVerifyEmail(email: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/pendingEmailAddressVerification/`, {
                email
            }
        );
    }

    public sendCodeToVerifyEmailSignup(email: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/pendingEmailAddressVerification/signup`, {
                email
            }
        );
    }

    public setupETransferWithCode(otp: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/berkeleyPayment/e-transfer/setup-with-code`, {
                code: otp
            }
        );
    }

    public checkEmailWhiteListStatus(email: string)  {
        return this.http.httpPostRequest(
            `/api/createUserFromAdmin/checkEmailStatus`,
            { email }
        );
    }

    public checkRequiredCertificateIsUploaded() {
        return this.httpPromiseService.httpGetRequest(
            `/api/certificates/checkRequiredCertificateIsUploaded`
        );
    }

    public async getCertificatesJSON(type?: string): Promise<ICertificateSpecification> {
        if(type.includes('&')) {
            type = encodeURIComponent(type);
        }
        
        return this.httpPromiseService.httpGetRequest(
            `/api/certificates/certificates-json?type=${type}`
        );
    }
    
    public acknowledgeBulletin(entityId: string, bulletinId: string, skill: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/bulletin-acknowledgments`
            , {
                entityId,
                bulletinId,
                skill
            });
    }

    public assembleBulletin(bulletinId: string,role : string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/bulletin-templates/${bulletinId}/compile`
            , {
                role
            })
        }
}