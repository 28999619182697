<div class = "list-container">
<ion-list>
<ion-grid>
  <ion-row class="main-wrapper">
    <form [formGroup] = "recurrentForm" *ngIf = "recurrentForm">
      <ion-row class = "block">
        <div class = "sub-heading">Change start date</div>
        <ion-col size = "3" class = "shift-date" formGroupName = "recurrentShiftTemplate">
          <app-form-date-picker
          [dateConfig] = "dateConfig"
          formControlName = "startDate"
          [control]="recurrentForm?.get('recurrentShiftTemplate')?.get('startDate')"
          (onChange) = "handleDateChanged($event)">
          </app-form-date-picker>
        </ion-col>
      </ion-row>

      <ion-row class = "margin-top-30">
        <ion-col size = "12">
          <div class = "sub-heading">Custom recurrence</div>
        </ion-col>
      </ion-row>

      
      <ion-row class = "vertical-uplift">
        <ion-col size="12" sizeMd="6">
          <ion-row>
            <ion-col size = "6" 
            class = "padding-right-20" 
            formGroupName = "recurrentShiftTemplate">
              <app-form-select-v2 
              class = "margin-right"
              [labelText]="'Repeats every'"
              [placeholder]="'Select Repetition'"
              [options] = "repeatIntervalList"
              [disableList] = "disableIntervals"
              formControlName = "repeatInterval"
              ></app-form-select-v2>
            </ion-col>

            <ion-col size = "6" 
            class = "padding-right-35 without-label-spacing" 
            formGroupName = "recurrentShiftTemplate">
              <app-form-select-v2
              class = "without-label"
              [labelText]="''"
              [placeholder]="'Select Week'"
              [options] = "frequencyList"
              (onChange)="handleFrequencyChange($event)"
              formControlName = "frequency"                  
              ></app-form-select-v2>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size = "6" 
            class = "padding-right-20" 
            formGroupName = "recurrentShiftTemplate">
              <app-form-select-v2
              [labelText]="'Start Time'"
              [placeholder]="'Select Start Time'"
              [options]="startTimeValues"
              [labelKey]="'formatted'"
              [valueKey]="'value'"
              [returnObject] = "true"
              [control]="recurrentForm?.get('recurrentShiftTemplate')?.get('startTime')"
              formControlName = "startTime"
              ></app-form-select-v2>
            
            </ion-col>
            <ion-col size = "6" 
            class = "padding-right-35" 
            formGroupName = "recurrentShiftTemplate">   
            <app-form-select-v2
              [labelText]="'End Time'"
              [placeholder]="'Select End Time'"
              [options]="endTimeValues"
              [labelKey]="'formatted'"
              [valueKey]="'value'"
              [returnObject] = "true"
              [control]="recurrentForm?.get('recurrentShiftTemplate')?.get('endTime')"
              formControlName = "endTime"
              ></app-form-select-v2>
              
            </ion-col>
          </ion-row>
        </ion-col>

        <ion-col size="12" sizeMd="6" class = "input second-col-recurrent">
          <ion-row>
            <ion-col size = "12" formGroupName = "recurrentShiftTemplate">
            <app-form-circular-checkbox
            class = "edit-shift"
            [labelText] = "'Repeat on'"
            [options] = "availableDays"
            [disableList] = "disableIntervals"
            [control] = "recurrentForm.get('recurrentShiftTemplate').get('days')"
            formControlName = "days">
          </app-form-circular-checkbox>
          </ion-col>
          </ion-row>

          <ion-row class = "end-option">
            <ion-col size = "12">
            <app-form-radio-buttons
            class = "edit-shift"
            [labelText] = "'Ends'"
            [options] = "availableEndOption">
          </app-form-radio-buttons>
          </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row class = "margin-top-20">
        <ion-col size = "12">
          <div class = "sub-heading">Broadcast Type</div>
        </ion-col>
      </ion-row>


      <ion-row>
        <ion-col size = "12">
        <app-form-radio-buttons
        class = "edit-shift"
        (onChange)="handleBroadcastTypeChange($event)"
        [options] = "availableBroadcastType">
      </app-form-radio-buttons>
      </ion-col>
      </ion-row>


    </form>
  </ion-row>
</ion-grid>
</ion-list>
</div>